import React, { useState } from 'react'
import styled from 'styled-components'

import { useTranslation } from 'lib/i18n'
import {
  TestimonialEntryStoryblok,
  TestimonialStoryblok,
} from 'lib/storyblok/types'
import { hasValidLink, textByLine } from 'lib/utils/content'

import { Container } from '../../ui/deprecated/Layout'
import { Link } from '../../ui/deprecated/Link'
import { Text } from '../../ui/deprecated/Text'
import { CMSLink } from './CMSLink'
import { Editable } from './Editable'

type Props = {
  block: TestimonialStoryblok
}

type Variant = 'full' | 'half'
type PositionX = 'left' | 'right' | 'center'
type PositionY = 'top' | 'bottom' | 'center'

interface ImageProps {
  testimonialImage: string
  positionX: PositionX
  positionY: PositionY
  variant: Variant
}

const SingleTestimonial = ({
  testimonial,
}: {
  testimonial: TestimonialEntryStoryblok
}) => {
  const [isTranslated, setIsTranslated] = useState<boolean>(false)
  const { i18n } = useTranslation()
  const {
    testimonial_image,
    testimonial_image_position_x,
    testimonial_image_position_y,
    quote,
    quote_original,
    author,
    link,
    link_label,
    variant = 'full',
    _uid,
  } = testimonial
  const testimonialQuote =
    isTranslated && quote_original ? quote_original : quote
  const testimonialImage = testimonial_image?.filename

  return (
    <>
      <Editable block={testimonial} key={_uid}>
        <ContentWrapper variant={variant}>
          <ContentInnerWrapper>
            {testimonialImage && (
              <Image
                variant={variant}
                testimonialImage={testimonialImage}
                positionX={testimonial_image_position_x || 'center'}
                positionY={testimonial_image_position_y || 'center'}
              />
            )}
            <Content hasTestimonialImage={!!testimonialImage}>
              <>
                {author && <Text as="span">- {author} -</Text>}
                <Body>
                  {textByLine(testimonialQuote, (part) => {
                    return (
                      <Text as="p" variant="twenty/regular">
                        {part}
                      </Text>
                    )
                  })}
                  {quote_original && (
                    <TestimonialTranslateWrapper>
                      <TestimonialTranslateLink
                        onClick={() => setIsTranslated(!isTranslated)}
                      >
                        <Text as="p" variant="fourteen">
                          {isTranslated
                            ? i18n('showTranslation')
                            : i18n('showOriginal')}
                        </Text>
                      </TestimonialTranslateLink>
                    </TestimonialTranslateWrapper>
                  )}
                </Body>
              </>
              <Footer>
                {link && link_label && hasValidLink(link) && (
                  <TestimonialLink href={link} variant="ghost" size="small">
                    {link_label}
                  </TestimonialLink>
                )}
              </Footer>
            </Content>
          </ContentInnerWrapper>
        </ContentWrapper>
      </Editable>
    </>
  )
}

export const Testimonial = ({ block, ...props }: Props): JSX.Element => {
  const { title, testimonials } = block

  return (
    <Wrapper {...props}>
      <Container>
        <Header>
          <Text as="h3" variant="twenty/regular">
            {title}
          </Text>
        </Header>
      </Container>

      <TestimonialsWrapper>
        {testimonials.map((testimonial) => {
          return (
            <SingleTestimonial
              key={testimonial._uid}
              testimonial={testimonial}
            />
          )
        })}
      </TestimonialsWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 3.5rem 0;
`

const Header = styled.div`
  padding-bottom: 2.357rem;
`

const TestimonialTranslateWrapper = styled.div`
  margin-top: 1rem;
`

const TestimonialTranslateLink = styled(Link)`
  cursor: pointer;
`

const TestimonialsWrapper = styled(Container)`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`
const ContentInnerWrapper = styled.div`
  min-height: 0;
  height: auto;

  display: flex;
  flex-direction: column;

  background-color: ${({ theme }) => `${theme.colors.palette.brand.default}0d`};
  border-radius: 16px;

  overflow: hidden;

  ${({ theme }) => theme.media.sm} {
    flex-direction: row;
    min-height: 420px;
  }
`

const ContentWrapper = styled.div<{ variant: Variant }>`
  margin-bottom: 2rem;

  width: 100%;
  max-width: 100%;

  ${({ theme }) => theme.media.lg} {
    max-width: ${({ variant }) =>
      variant === 'full' ? '100%' : 'calc(50% - 1rem)'};
  }
`

const Content = styled.div<{ hasTestimonialImage: boolean }>`
  padding: 1.25rem;

  display: flex;
  flex-direction: column;
  margin: 0 auto;

  height: auto;
  width: 100%;
  max-width: 100%;

  ${({ theme }) => theme.media.sm} {
    padding: 2.5rem;
  }

  ${({ theme }) => theme.media.lg} {
    max-width: ${({ hasTestimonialImage }) =>
      hasTestimonialImage ? '360px' : '100%'};
    flex: 1 0 100%;
  }
`

const Body = styled.div`
  flex: 1;

  padding: 1.5rem 0;
`

const Footer = styled.div`
  display: flex;
  align-items: center;
`

const TestimonialLink = styled(CMSLink)`
  border-color: ${({ theme }) => `${theme.colors.foreground.default}1a`};
`
const Image = styled.div<ImageProps>`
  width: 100%;
  height: 180px;
  background-image: url(${({ testimonialImage }) => testimonialImage});
  background-size: cover;
  background-position: ${({ positionX, positionY }) =>
    `${positionX} ${positionY}`};

  ${({ theme }) => theme.media.sm} {
    height: auto;
  }
`
